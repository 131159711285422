/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

// Bootstrap
require('bootstrap');

// Fontawesome
require('@fortawesome/fontawesome-free/css/all.min.css');

// CookieConsent
require('CookieConsent');
require('cookieconsent/build/cookieconsent.min.css');

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.scss';


// Engines ready - Start
$(document).ready(function() {
    console.log('Engines ready');

    // Popover
    $('[data-toggle="popover"]').popover();

    // CookieConsent initialisation
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#edeff5",
                "text": "#838391"
            },
            "button": {
                "background": "#007bff"
            }
        },
        "content": {
            "message": "Deze website gebruikt cookies om jou de beste mogelijke ervaring te leveren.",
            "dismiss": "Begrepen!",
            "link": "Wat zijn cookies?"
        }
    });
});
